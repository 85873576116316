import Vue from "vue";
// 引入组件
import AlertTipsDialog from "@/components/common/AlertTipsDialog.vue";

/**
 * 注册删除确认对话框
 * @param {Object} options 配置项
 * @param {String} options.msg 提示信息
 * @param {Function} options.callBack 回调函数
 * @param {String} options.title 标题
 */
AlertTipsDialog.install = function (options) {
    // 创建构造器
    const AlertTipsBox = Vue.extend(AlertTipsDialog);
    // 创建组件对象，并挂载
    let instance = new AlertTipsBox(); // $mount(document.createElement('div') 【也可以挂载到某个元素上】

    // 将组件添加到dom中
    const id = "alert-tips-dialog";
    const el = document.createElement("div");
    el.id = id;
    document.body.appendChild(el);

    instance.$mount(`#${id}`);

    // 调用方法或操作属性
    Vue.nextTick(() => {
        instance.open(options);
    });
};

export default AlertTipsDialog;
